import { useSearchParams } from "react-router-dom";
import {
  Auth,
  getAuth,
  applyActionCode,
  confirmPasswordReset,
  verifyPasswordResetCode,
} from "firebase/auth";

export const MailActionHandler = () => {
  const auth = getAuth();
  const [searchParams] = useSearchParams();
  const mode = searchParams.get("mode");
  const actionCode = searchParams.get("oobCode");
  const continueUrl = searchParams.get("continueUrl");
  const lang = searchParams.get("lang") || "en";

  console.log({ mode, actionCode, continueUrl, lang });

  const handleResetPassword = (
    auth: Auth,
    actionCode: string,
    continueUrl: string,
    lang: string
  ) => {
    verifyPasswordResetCode(auth, actionCode)
      .then((email) => {
        const accountEmail = email;
        const newPassword = "...";
        confirmPasswordReset(auth, actionCode, newPassword)
          .then((resp) => {
            console.log(resp);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleVerifyEmail = (
    auth: Auth,
    actionCode: string,
    continueUrl: string,
    lang: string
  ) => {
    applyActionCode(auth, actionCode)
      .then((resp) => {
        console.log(resp);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  switch (mode) {
    case "resetPassword":
      handleResetPassword(auth, actionCode, continueUrl, lang);
      break;
    case "verifyEmail":
      handleVerifyEmail(auth, actionCode, continueUrl, lang);
      break;
    default:
  }

  return <div></div>;
};
